class photoUploadConfig {
  getConfig() {
    return {
      default: 'default',
      page: 'default',
      product: 'default',
      banner: 'banner',
      etc: 'default',
      site_admin: 'avatar',
      member: 'avatar',
    }
  }
}

export default new photoUploadConfig()