import Vue from 'vue'
import bootstrap from 'modules/bootstrap'
import {EagleModule} from 'kernel/types/module'
export default Vue.extend({
  methods: {
    async initModules() {
      bootstrap.setupVueModel(this)
      await bootstrap.getEachModule(async (moduleName, module) => {
        await this.launchModule(moduleName, module)
        await this.setupApi(moduleName, module)
      })
      await bootstrap.setupMenu()
    },
    async launchModule(moduleName: string, module: EagleModule) {
      if(typeof module.launch === 'function') {
        await module.launch(this)
      }
    },
    async setupApi(moduleName: string, module: EagleModule) {
      if(typeof module.realApi != 'function') return
      if((module.realApi() instanceof Promise) != true) return
      const realApi = await module.realApi()
      // @ts-ignore
      const realApiCallback = realApi.default
      if(typeof realApiCallback != 'function') return
      realApiCallback(this.$api)
    },
  },
})
