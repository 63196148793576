import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import initModules from 'app/initModules'
import initEventListeners from 'app/initEventListeners'
import 'app/initPlugins'
import { mapGetters } from 'vuex'
import api from 'kernel/api/api'
import eagleEnv from 'config/env.json'
import recaptcha from 'kernel/libs/recaptcha'
Vue.prototype.$api = api(eagleEnv)

export default mixins(
  initModules,
  initEventListeners,
).extend({
  methods: {
    async initClient() : Promise<void> {
      this.$store.dispatch('loading/active')
      this.$store.dispatch('base/setupLayoutReady', false)
      await this.initKernel()
      await this.setupEventListeners()
      await this.initModules()
      await this.loadApplication()
      this.$store.dispatch('base/setupLayoutReady', true)
      this.$store.dispatch('loading/close')
    },
    async initKernel() {
      window.helper.setupVueModel(this)
      this.$api.setup(this)
      this.$tokenStore.setupApi(this.$api)
      await this.$helper.delay(0.3)
    },
    async loadApplication() {
      try {
        const result = await this.$api.collection.baseApi.application()
        this.$store.dispatch('base/initApplication', result)
      } catch (error) {
        console.error(error)
        if(error === undefined) {
          this.$systemBar.active({
            title: this.$t('error.server_fail'),
            content: this.$t('error.server_fail.content'),
            color: 'error',
          })
        }

        this.$store.dispatch('loading/close')
      } finally {
        this.initRecaptcha()
      }
    },
    initRecaptcha() {
      Vue.prototype.$recaptchaLibrary = recaptcha(this)
    },
  },
  computed: {
    ...mapGetters({
      layoutReady: 'base/layoutReady',
    })
  },
  components: {
    loading: () => import('components/loading/loading.vue'),
    progressLoading: () => import('components/loading/progressLoading.vue'),
  },
})
