import Vue from 'vue'
import VueI18n, {
  LocaleMessages, Path, Values, Locale
  // @ts-ignore
} from 'vue-i18n'
Vue.use(VueI18n)

// 合併前端的i18n js
function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('kernel/i18n/languages', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

// @ts-ignore
declare module 'vue-i18n/types' {
  export default class VueI18n {
    t(key: Path, locale: Locale, values?: Values): string;
    t(key: Path, values?: Values): string;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $t: typeof VueI18n.prototype.t;
  }

  interface VueConstructor<V extends Vue = Vue> {
    i18n: typeof VueI18n.prototype;
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zh-TW',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-TW',
  messages: loadLocaleMessages(),
  modifiers: {},
  silentTranslationWarn: true,
  silentFallbackWarn: true,
})
