import Vue from 'vue'

import {PopupHelperInterface} from 'plugins/anchorPopup/types'

declare type moduleSelectorConfig = {
  multiple?: true,
  max?: number,
  title: string,
  bodySlot: any,
  usedList?: [any[]],
  applyCallback: (data: any) => any,
  closeCallback?: (() => any),
  usedListMatchCallback?: (() => boolean)|null,
  meta?: any,
}

class moduleSelector {
  public active(vm: Vue, config: moduleSelectorConfig) {
    let max:any = undefined
    if(config.max === undefined) {
      max = config.multiple === true ? 0 : 1
    }

    else {
      // @ts-ignore
      max = parseInt(config.max)
    }

    vm.$apopup.base({
      width: '80vw',
      max,
      multiple: config.multiple === true,
      title: config.title,
      bodySlot: config.bodySlot,
      usedListMatchCallback: config.usedListMatchCallback || null,
      usedList: !Array.isArray(config.usedList) ? [] : window.eagleLodash.cloneDeep(config.usedList),
      onDataChange: (popupHelper: PopupHelperInterface) => {
        const quantity = this.getDataLength(popupHelper.data)
        let title = `${popupHelper.title}(${quantity})`
        if(max != 0) {
          title = `${popupHelper.title}(${quantity}/${max})`
        }
        popupHelper.setTitle(title)
      },
      disabledApply: (data: any) : boolean => {
        if(!Array.isArray(data)) return true
        const length = data.length
        if(length == 0) return true
        if(length > max && max != 0) return true
        return false
      },
      applyCallback: data => {
        const result = this._getApplyData(data, config.multiple === true)
        config.applyCallback(result)
      },
      closeCallback: config.closeCallback,
      ...config.meta,
    })
  }

  private getDataLength(data: any) : number {
    if(!data) return 0
    if(!Array.isArray(data)) return 1
    return data.length
  }

  private _getApplyData(data: any, multiple: boolean) {
    if(!Array.isArray(data)) return null
    if(!multiple) return data[0] || null
    return data
  }
}

export default new moduleSelector()

export interface ModuleSelectorInterface {
  active: (vm: Vue, config: moduleSelectorConfig) => void,
}

export type ModuleSelectorConfigType = moduleSelectorConfig
