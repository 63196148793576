import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css'
import colors from 'vuetify/es5/util/colors'
Vue.use(Vuetify);

const customColor = {
  'brand-google': '#DD4B39',
  'brand-facebook': '#3965DD',
  'brand-line': '#3acd01',
}

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue.base,
        accent: colors.blue.lighten2,
        info: colors.teal.base,
        warning: colors.amber.base,
        error: colors.red.lighten1,
        success: colors.green.lighten1,
        ...customColor,
      },
    }
  }
});
