import Vue from 'vue'
import deepmerge from 'deepmerge'
import _cloneDeep from 'lodash/cloneDeep'
import 'kernel/libs/lodashLoader'

class bootstrap {
  protected modules: any
  protected routes: any
  protected menu: any
  protected vm: Vue|null
  constructor() {
    this.vm = null
    this.modules = {}
    this.routes = {}
    this.menu = {}
  }

  /**
   * 自動合併modules底下每個有index.ts的目錄到this.modules
   */
  async init() {
    const moduleConfigs = require.context('modules', true, /(.*)\/index.ts$/i)
    moduleConfigs.keys().forEach(key => {
      const module = moduleConfigs(key).default
      this.modules[module.name] = module
    })

    await this.setupRoutes()
  }

  setupVueModel(vm: Vue) {
    this.vm = vm
  }

  getModules() {
    return _cloneDeep(this.modules)
  }

  /**
   * 自動迭代所有的模組設定檔
   * @param callback
   */
  // eslint-disable-next-line
  async getEachModule(callback = (moduleName: string, module: any) => {}) {
    for(const moduleName in this.modules) {
      const module = _cloneDeep(this.modules[moduleName])
      const moduleConfig = typeof module.getModuleConfig == 'function' ? module.getModuleConfig() : null
      await callback(moduleName, moduleConfig)
    }
  }

  async setupRoutes() {
    await this.getEachModule(async (moduleName: string, module: any) => {
      if(typeof module.routes != 'function') return
      const routes = (await module.routes()).default
      this.routes = deepmerge(this.routes, routes)
    })
  }

  async setupMenu() {
    await this.getEachModule(async (moduleName: string, module: any) => {
      if(typeof module.menu != 'function') return
      const menu = (await module.menu()).default()
      this.menu = deepmerge(this.menu, menu)
    })
  }

  getRoutes() {
    return this.routes
  }

  getMenu(type?: string) : any {
    if(!type) return this.menu
    return this.menu[type]
  }

}

export default new bootstrap()