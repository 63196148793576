// @ts-ignore
import crosstabLibrary from 'crosstab'

class CrossTab {
  on(eventName: string, callback: null|((event: AnyObject) => void)) {
    try {
      crosstabLibrary.on(eventName, (event: AnyObject, tabId: string) => {
        if(typeof callback != 'function') return
        // @ts-ignore
        callback(event, crosstabLibrary.id)
      })
    } catch(error) {
      console.warn('error', error)
      console.log(`device not support crosstab [${eventName}]`)
    }
  }

  broadcast(eventName: string, data: null|AnyObject) {
    try {
      crosstabLibrary.broadcast(eventName, data , null)
    } catch(error) {
      console.warn('error', error)
      console.log(`device not support crosstab [${eventName}]`)
    }
  }
}

export default new CrossTab()

export interface CrossTabInterface {
  on: (eventName: string, callback: null|((event: AnyObject, tabId: string) => void)) => void,
  broadcast: (eventName: string, data: null|AnyObject) => void,
}
