import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'
import otpConstants from 'modules/base/config/otpConstants'
Vue.prototype.$otpConstants = otpConstants
class moduleIndex {
  private vm: Vue|undefined
  public name: string
  constructor() {
    this.name = 'base'
  }

  // 模組啟動, 當有使用的時候會帶入vm
  public async launch(vm: Vue) : Promise<void> {
    this.vm = vm
    Vue.prototype.$photoSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('photo_manager'),
        bodySlot: () => import('modules/base/selector/photoList.vue'),
      })
    }

    Vue.prototype.$fileSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('file_manager'),
        bodySlot: () => import('modules/base/selector/fileList.vue'),
      })
    }
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/base/config/menu'),
      realApi: () => import('modules/base/config/realApi'),
      routes: () => import('modules/base/config/route'),
    }
  }
}

export default new moduleIndex()
