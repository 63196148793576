import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'

class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'siteAdmin'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$siteAdminSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('module.site_admin'),
        bodySlot: () => import('modules/siteAdmin/selector/siteAdminList.vue'),
      })
    }

    Vue.prototype.$siteAdminGroupSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('module.site_admin_group'),
        bodySlot: () => import('modules/siteAdmin/selector/siteAdminGroupList.vue'),
      })
    }
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/siteAdmin/config/menu'),
      realApi: () => import('modules/siteAdmin/config/realApi'),
      routes: () => import('modules/siteAdmin/config/route'),
    }
  }
}

export default new moduleIndex()
