import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import base from '@/store/modules/base'
import accounting from '@/store/modules/accounting'
import loading from '@/store/modules/loading'
import token from '@/store/modules/token'
import list from '@/store/modules/list'
import form from '@/store/modules/form'
import tree from '@/store/modules/tree'
import photo from '@/store/modules/photo'
export default new Vuex.Store({
  modules: {
    base,
    accounting,
    loading,
    token,
    list,
    form,
    tree,
    photo,
  }
})
