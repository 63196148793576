import {ActionContext} from 'vuex/types'
import type {RecordItemInterface, RecommendedCostItemInterface} from '@/types/accounting'
import accountingConstants from 'modules/accounting/config/accountingConstants'

interface RecordsDataInterface {
  data: RecordItemInterface[]
}

interface StateInterface {
  records: RecordItemInterface[]
  recommendedCosts: RecommendedCostItemInterface[],
}

export default {
  namespaced: true,
  state: <StateInterface>{
    records: [],
    incomeTotal: 0,
    taxTotal: 0,
    costTotal: 0,
    costBreakdown: {},
    recommendedCosts: [],
  },
  mutations: {
    setRecords: (state: StateInterface, result: RecordsDataInterface) : void => {
      state.records = result.data
    },
    setRecommendedCosts: (state: StateInterface, data: RecommendedCostItemInterface[]) : void => {
      state.recommendedCosts = data
    },
    removeRecord: (state: StateInterface, id: number) : void => {
      state.records = state.records.filter((record: RecordItemInterface) => record.id !== id)
    },
    resetRecords: (state: StateInterface) : void => {
      state.records = []
    },
  },
  actions: {},
  getters: {
    hasRecord: (state: StateInterface) : boolean => state.records.length > 0,
    records: (state: StateInterface) : RecordItemInterface[] => state.records,
    recordsOfDate: (state: StateInterface) : ((date: string) => RecordItemInterface[]) => {
      return (date: string) => {
        return state.records.filter((record: RecordItemInterface) => record.date === date)
      }
    },
    incomeTotal: (state: StateInterface) : number => {
      const incomeRecords = state.records
        .filter((record: RecordItemInterface) => record.record_type == accountingConstants.TYPE_INCOME)
      return window.eagleLodash.sumBy(incomeRecords, 'amount')
    },
    taxTotal: (state: StateInterface) : number => {
      const taxRecords = state.records
        .filter((record: RecordItemInterface) => record.record_type == accountingConstants.TYPE_TAX)
      return window.eagleLodash.sumBy(taxRecords, 'amount')
    
    },
    costTotal: (state: StateInterface) : number => {
      const costRecords = state.records
        .filter((record: RecordItemInterface) => record.record_type == accountingConstants.TYPE_COST)
      return window.eagleLodash.sumBy(costRecords, 'amount')
    
    },
    costBreakdown: (state: StateInterface) : {[key: string]: number} => {
      const costRecords = state.records
        .filter((record: RecordItemInterface) => record.record_type == accountingConstants.TYPE_COST)

      const result:any = {}
      for(const costType of accountingConstants.COST_TYPES) {
        const records = costRecords.filter((record: RecordItemInterface) => record.type == costType)
        result[costType] = window.eagleLodash.sumBy(records, 'amount')
      
      }
      return result
    },
    recommendedCosts: (state: StateInterface) : RecommendedCostItemInterface[] => state.recommendedCosts,
  },
}
