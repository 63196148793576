// @ts-ignore
import Vue from 'vue'
import { events } from './events'
import systemBar from './systemBar.vue'
export interface SystemBarOptionsInterface {
  timeout?: number|string|null,
  dark?: boolean|undefined,
  buttonCallback?: () => void,
  buttonText?: string|number|null,
  title: any,
  content?: any,
  color?: string|null,
  closeable?: boolean,
  fixed?: true|boolean,
}

class Installer {
  protected isInstalled: boolean
  protected emitter: Vue

  constructor() {
    this.isInstalled = false
    this.emitter = events
  }

  install() {
    if(this.isInstalled) return
    this.isInstalled = true
    Vue.component('eagle-system-bar', systemBar)
    Vue.prototype.$systemBar = {
      active: (options: SystemBarOptionsInterface) => {
        this.emitter.$emit('active', options)
      }
    }
  }
}

export default new Installer()
export interface SystemBarInterface {
  active: (option: SystemBarOptionsInterface) => void
}