import {ActionContext} from 'vuex/types'
interface State {
  status: boolean,
  title: string|number|null
  description: string|number|null
  progress: boolean,
  progressValue: number,
}

interface activeOptions {
  title?: string|number,
  description?: string|number,
}

export default {
  namespaced: true,
  state: <State>{
    status: false,
    title: null,
    description: null,
    progress: false,
    progressValue: -1
  },
  mutations: {
    active: (state: State, options?: string|activeOptions) : void => {
      state.status = true

      if(typeof options === 'string') {
        state.title = options
        return
      }

      if(typeof options === 'object') {
        state.title = options.title || ''
        state.description = options.description || ''
      }
    },
    close(state: State) {
      state.status = false
      state.title = null
    },
    progress: (state: State, options?: {[key:string]: any}) : void => {
      state.progress = true
      if(options && options.value) {
        state.progressValue = options.value
      }
    },
    closeProgress(state: State) {
      state.progress = false
      state.progressValue = -1
    },
  },
  actions: {
    active: (context: ActionContext<any, any>, options?: string|number|activeOptions) : void => {
      if(typeof options === 'number') options = options.toString()
      context.commit('active', options)
    },
    close(context: ActionContext<any, any>) {
      context.commit('close')
    },
    progress(context: ActionContext<any, any>, options?: {[key:string]: any}) {
      context.commit('progress', options)
    },
    closeProgress(context: ActionContext<any, any>) {
      context.commit('closeProgress')
    },
  },
  getters: {
    status: (state: State) : boolean => state.status,
    title: (state: State) : string|number|null => state.title,
    description: (state: State) : string|number|null => state.description,
    progress: (state: State) : boolean => state.progress,
  },
}
