import {ActionContext} from 'vuex/types'

// @ts-ignore
import photoUploadConfig from 'config/photoUploadConfig'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {
    photoUploadConfig: () : any => photoUploadConfig.getConfig(),
  },
}
