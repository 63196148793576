import Vue from 'vue'
export default Vue.extend({
  methods: {
    setupEventListeners() {
      window.addEventListener('online', () => this.networkOnlineEvent())
      window.addEventListener('offline', () => this.networkOfflineEvent())
    },
    networkOnlineEvent() {
      this.$systemBar.active({
        title: this.$t('alert'),
        content: this.$t('network.online'),
        color: 'green',
        timeout: 3000,
      })
    },
    networkOfflineEvent() {
      this.$systemBar.active({
        title: this.$t('alert'),
        content: this.$t('network.offline'),
        color: 'orange',
      })
    },
  },
})