
















import Vue from 'vue'
// @ts-ignore
import AnchorPopup from '@/plugins/anchorPopup/installer.js'
import Snotify from 'vue-snotify'
Vue.use(Snotify, {
  toast: { timeout: 5000, titleMaxLength: 32 },
})

import initClient from '@/app/initClient'
import mixins from 'vue-typed-mixins'
export default mixins(initClient).extend({
  metaInfo() {
    return {
      titleTemplate: (titleChunk) => {
        // @ts-ignore
        return titleChunk ? `${titleChunk} | ${this.siteName}` : this.siteName
      },
    }
  },
  created() {
    Vue.use(AnchorPopup, this.anchorPopupDefaultConfig)
  },
  async mounted() {
    await this.initClient()
  },
  computed: {
    anchorPopupDefaultConfig() {
      return {
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      }
    },
    siteName() {
      return this.$store.getters['base/siteName']
    },
  },
})
