class vueRouteChangePrevent {
  protected rules: {[key: string]: any}

  public constructor() {
    this.rules = {}
  }

  public setup(key: string, status: boolean) : void {
    if(status === true) {
      this.rules[key] = true
    }

    else {
      delete this.rules[key]
    }
  }

  public hasPrevent(key?: string) : boolean {
    if(!key) return window.eagleLodash.isEmpty(this.rules) === false
    return !!this.rules[key]
  }

  public reset() : void {
    this.rules = {}
  }
}

export default new vueRouteChangePrevent()

export interface VueRouteChangePreventInterface {
  setup: (key: string, status: boolean) => void,
  hasPrevent: (key?: string) => boolean,
  reset: () => void,
}
