import Vue from 'vue'
// @ts-ignore
import storage from 'libs/storage/localStorage.js'
import { TokenType } from 'kernel/libs/tokenStore'

class account {
  protected helper: any
  protected vm: Vue
  public storage: any

  constructor(helper: any, vm: Vue) {
    this.helper = helper
    this.vm = vm
    this.storage = storage()
  }

  login(keepLogin: boolean = false, rememberEmail:boolean = false) {
    if(!this.vm) return
    this.vm.$snotify.success(
      this.vm.$t('notify.welcome', { name: window.tokenStore.name }),
      this.vm.$t('notify.login.successfully')
    )

    if(rememberEmail == true) {
      this._loginStoreLoginAccount()
    }

    else {
      this.cleanStorageAccountProfile('admin')
    }
  }

  _loginStoreLoginAccount() {
    try {
      const tokenType = window.tokenStore.type
      const avatar = window.eagleLodash.get(window.tokenStore, ['data.avatar'])
      const profile = {
        name: window.eagleLodash.get(window.tokenStore.data, 'name') || window.eagleLodash.get(window.tokenStore.data, 'email'),
        email: window.eagleLodash.get(window.tokenStore.data, 'email'),
        avatar: this.helper.getPhotoUrl(avatar)
      }

      this.storage.set(`profile_${tokenType}`, profile)
    } catch (error) {
      console.warn(error)
    }
  }

  getStorageAccountProfile(type: TokenType) : any {
    return this.storage.get(`profile_${type}`)
  }

  cleanStorageAccountProfile(type: TokenType) : void {
    this.storage.delete(`profile_${type}`)
  }

  logout() {
    if(!this.vm) return
    // @ts-ignore
    this.vm.$apopup.base({
      title: this.vm.$t(`logout.confirm`),
      applyCallback: async () => {
        await this.logoutRequest()
        window.tokenStore.clean()
        // @ts-ignore
        this.vm.$snotify.success(null, '登出完成')
      },
    })
  }

  async logoutRequest() {
    try {
      await this.vm.$api.collection.siteAdminAccountApi.logout()
    } catch (error) {
      console.warn(error)
    }
  }

  $t(text: any) {
    return this.vm.$t(text)
  }

  // 驗證身份
  async verify(callback?: (() => void)) {
    await this.helper.delay(0.5)
    this.vm.$apopup.prompt({
      title: this.$t('action.account-verify'),
      inputType: 'password',
      promptPlaceholder: this.$t('action.account-verify.help'),
      promptMaxLength: 50,
      defaultValue: null,
      disabledApply: data => this.vm.$helper.textEmpty(data),
      applyCallback: async password => {
        if(typeof password != 'string') {
          password = JSON.stringify(password)
        }
        try {
          await this.vm.$api.collection.siteAdminAccountApi.verify(password)
          // @ts-ignore
          this.vm.$snotify.info(null, this.$t('notify.account-verify.successfully'))
          if(typeof callback === 'function') callback()
        } catch (error) {
          console.error(error)
          this.vm.$snotify.error(
            this.$t('notify.account-verify.failure.content'),
            this.$t('notify.account-verify.failure'),
          )
          throw error
        }
      },
    })
  }
}

export default (helper: any, vm: Vue) => new account(helper, vm)

export interface AccountInterface {
  login: (keepLogin?: boolean, rememberEmail?: boolean) => void
  logout: () => void
  getStorageAccountProfile: (type: TokenType) => any
  cleanStorageAccountProfile: (type: TokenType) => void
  verify: (callback?: (() => void)) => void
}
