import Vue from 'vue'

import snackbar from 'plugins/snackbar'
Vue.use(snackbar)

import systemBar from 'plugins/systemBar'
Vue.use(systemBar)

// @ts-ignore
import fingerprint from 'fingerprintjs'
Vue.prototype.$fingerprint = new fingerprint()

// https://github.com/validatorjs/validator.js
// @ts-ignore
import validator from 'validator'
Vue.prototype.$validator = validator

// @ts-ignore
import Dayjs from 'vue-dayjs'
Vue.use(Dayjs)

// 複製套件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// 快捷鍵套件
// @ts-ignore
import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

// 圖片顯示器
import Viewer from 'v-viewer'
Vue.use(Viewer, {
  defaultOptions: {
    toolbar: {
      zoomIn: true,
      prev: true,
      reset: true,
      next: true,
      zoomOut: true,
    },
  },
})

import vuedraggable from 'vuedraggable'
Vue.component('v-draggable', vuedraggable)

import moduleSelector from 'kernel/libs/moduleSelector'
Vue.prototype.$moduleSelector = moduleSelector

Vue.component('eagle-password', () => import('components/password/password.vue'))
Vue.component('html-content', () => import('components/htmlContent/htmlContent.vue'))
Vue.component('v-html-editor', () => import('components/tinymce/tinymce.vue'))
Vue.component('copy-icon', () => import('components/copy/icon.vue'))
Vue.component('photo-viewer', () => import('components/photoViewer/photoViewer.vue'))
Vue.component('photo-selector', () => import('components/photoSelector/photoSelector.vue'))
Vue.component('invisible-photo-uploader', () => import('components/invisiblePhotoUploader/invisiblePhotoUploader.vue'))
Vue.component('invisible-file-uploader', () => import('components/invisibleFileUploader/invisibleFileUploader.vue'))
Vue.component('datetime-picker', () => import('components/datetimePicker/datetimePicker.vue'))
Vue.component('breadcrumb', () => import('components/breadcrumb/breadcrumb.vue'))
Vue.component('data-not-found', () => import('components/dataNotFound/dataNotFound.vue'))
Vue.component('file-uploader', () => import('components/fileUploader/fileUploader.vue'))
Vue.component('form-post', () => import('components/formPost/formPost.vue'))
Vue.component('video-player', () => import('components/videoPlayer/videoPlayer.vue'))
