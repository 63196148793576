import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
declare type setTokenCallbackType = (token: string) => any

class recaptcha {
  protected vm: Vue
  protected token: string|null

  constructor(vm: Vue) {
    this.vm = vm
    this.token = null
  }

  async load() {
    if(!this.getRecaptchaStatus()) return

    Vue.use(VueReCaptcha, { siteKey: this.getSiteKey() })
    this.show()
    await this.vm.$recaptchaLoaded()
  }

  async execute(setTokenCallback: setTokenCallbackType) {
    if(!this.getRecaptchaStatus()) return

    this.token = await this.vm.$recaptcha('login')
    if(typeof setTokenCallback == 'function') {
      setTokenCallback(this.token)
    }
  }

  getSiteKey() {
    // @ts-ignore
    return this.vm.$store.getters['base/recaptchaClient']
  }

  getRecaptchaStatus() {
    // @ts-ignore
    return this.vm.$store.getters['base/recaptchaStatus']
  }

  destroy() {
    if(!this.vm.$recaptchaInstance) return
    if(typeof this.vm.$recaptchaInstance.hideBadge != 'function') return
    this.vm.$recaptchaInstance.hideBadge()
  }

  show() {
    if(!this.vm.$recaptchaInstance) return
    if(typeof this.vm.$recaptchaInstance.showBadge != 'function') return
    this.vm.$recaptchaInstance.showBadge()
  }
}

export default (vm: Vue) => {
  return new recaptcha(vm)
}
