module.exports = {
  // 寄送類型
  TYPE_FACE_TO_FACE: 'face_to_face', // 面交(非第三方)
  TYPE_HOME_TCAT: 'home_tcat', // 宅配-黑貓
  TYPE_HOME_ECAN: 'home_ecan', // 宅配-宅配通
  TYPE_CVS_SEVEN: 'cvs_seven', // 7-11
  TYPE_CVS_FAMI: 'cvs_fami', // 全家
  TYPE_CVS_HILIFE: 'cvs_hilife', // 萊爾富
  TYPE_CVS_OK: 'cvs_ok', // OK
}
